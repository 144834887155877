<template>
    <v-container fluid>
        <v-card
            width="380"
            height="500"
            class="mx-auto"
            elevation="2"
            outlined
        >
            <v-card-title
                class="secondary--text primary"
            >
                Set / Update Current Term
            </v-card-title>
            <v-card-text
                class="pt-6"
            >
                <v-combobox
                    label="Academic Year"                    
                    :items="academicYear"
                    text="text"
                    value="value"
                    v-model="selectedYear"
                    @change="getNewTermBeginning"
                ></v-combobox>
                <v-select
                    label="Term"                    
                    :items="terms"
                    item-text="title"
                    item-value="id"
                    v-model="selectedTerm"
                    @change="getNewTermBeginning"
                ></v-select>
                <v-text-field
                    label="Possible Attendance"
                    v-model="possibleAttendance"
                    type="number"
                    class="mb-4"
                    disabled
                    filled
                ></v-text-field>                
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"                   
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field                        
                        :value="formattedDate"
                        label="New Term Beginning"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    ref="picker"
                    v-model="date"
                    
                    min="1950-01-01"
                    @change="save"
                    ></v-date-picker>
                </v-menu>
                
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="registerStudentsMenu"
                    outlined                
                >
                    Register Students
                </v-btn>                
                <v-btn
                    color="primary"
                    dark
                    outlined
                    @click="setTerm"                    
                >
                    Set / Update Term
                </v-btn>
            </v-card-actions>

            <v-overlay
                :absolute="absolute"
                :value="overlay"
                opacity="0.5"
                color="#A0A0A0"
            >
                <v-card
                    light
                    class="pa-4"
                    max-width="300"
                >
                    <v-card-text>
                        <v-autocomplete
                            v-model="selectedFormClass"
                            :items="formClasses"
                            chips
                            deletable-chips
                            label="Select Class"
                        ></v-autocomplete>
                    </v-card-text>
                    <v-card-actions class="pa-0">
                        <v-btn
                            color="primary"
                            outlined
                            @click="overlay = !overlay"
                            class="flex-grow-1"
                        >
                            Cancel
                        </v-btn>                        
                        <v-btn
                            dark
                            color="primary"                           
                            @click="registerStudents"
                        >
                            Register Students
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-overlay>
        </v-card>
    </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { format, parseISO } from 'date-fns';

export default {
    name: 'AdminDashboard',
    mounted: function () {
        this.initialize();
    },
    
    computed: {
        formattedDate () {
            return this.date ? format(parseISO(this.date), 'dd-MMM-yyyy') : '';
        },

        ...mapGetters({
            progressOverlay: 'admin/getProgressOverlay',            
        })
    },

    data: () => ({
        academicYear: [],            
        terms: [],
        termArchives: [],
        selectedYear: '',
        selectedTerm: '',
        possibleAttendance: '',
        newTermBeginning: '',
        date: null,
        menu: false,
        overlay: false,
        absolute: true,
        selectedFormClass: 'All',
        formClasses: [],   
    }),

    watch: {
        menu (val) {
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
    },
    
    methods: {        
        ...mapMutations({
            setModal: 'admin/setModal',
            setModalTitle: 'admin/setModalTitle',
            setModalMessage: 'admin/setModalMessage',
            setModalIcon: 'admin/setModalIcon',
            setOverlay: 'admin/setProgressOverlay',
            setAcademicTerm: 'admin/setAcademicTerm',
            setAcademicYearId: 'admin/setAcademicYearId',            
            setFormClassSelected: 'admin/setSelectedFormClass',
            setTermRegistrationProgress: 'admin/setTermRegistrationProgress',
        }),

        ...mapActions({            
            register: 'admin/registerStudents',
            setCurrentAcademicTerm: 'admin/setCurrentAcademicTerm',
            getCurrentAcademicTerm: 'admin/getCurrentAcademicTerm',
            getFormClasses: 'admin/getFormClasses',
            getAcademicYears: 'admin/getAcademicYears',
            clearFlags: 'admin/clearFlags',
        }),

        async initialize () {
            this.academicYear = [];
            this.setOverlay(true);
            try {
                const academicTermPromise = this.getCurrentAcademicTerm();
                const formClassesPromise = this.getFormClasses();
                const promiseAcademicYears = this.getAcademicYears();
                
                const [
                    { data: dataTerm },
                    { data: dataFormClasses},
                    { data: dataYears },
                ] =
                await Promise.all([
                    academicTermPromise, 
                    formClassesPromise,
                    promiseAcademicYears
                ]);
                
                const { terms, current_term, terms_archive } = dataTerm;
                this.terms = terms;
                this.termArchives = terms_archive;
                this.setAcademicPeriod(current_term);
                this.setFormClasses(dataFormClasses);
                this.setAcademicYears(dataYears);
                
                // .then( responses => {
                //     // console.log(responses[0]);
                //     const { terms, current_term } = responses[0].data;
                //     this.setAcademicPeriod(current_term);
                //     this.terms = terms                    
                //     this.setFormClasses(responses[1]);
                                        
                // });                
                
            } catch (error) {
                if(error.response) console.log(error.response)
                else console.log(error);
            }

            this.setOverlay(false);
        },

        setAcademicYears (data) {
            let maxYear = 0;
            let yearStart = 0;
            let yearEnd = 0;
            data.forEach(({ id }) => {
                yearStart = id.toString().substring(0,4);
                yearEnd = id.toString().substring(4)
                this.academicYear.push(
                    yearStart + '-' + yearEnd
                )
                if(yearEnd > maxYear){
                    maxYear = yearEnd;
                }
            })
            this.academicYear.push(
                maxYear + '-' + (parseInt(maxYear)+1)
            )
        },
        
        setAcademicPeriod (current_term) {
            let academicYear = current_term.academic_year_id.toString();
            this.selectedYear = academicYear.substring(0,4) + '-' + academicYear.substring(4);
            this.selectedTerm = current_term.term;
            this.possibleAttendance = current_term.possible_attendance;
            this.date = current_term.new_term_beginning;
            this.setAcademicYearId(current_term.academic_year_id);
        },

        setFormClasses (data) {
            this.formClasses.push('All');
            data.forEach(value => {
                if(value.form_level)
                this.formClasses.push(value.id);
            });
            // console.log(this.formClasses);
        },

        async registerStudents(){            
            if(this.selectedFormClass != 'All')
            this.setFormClassSelected(this.selectedFormClass);

            if(!this.selectedYear || !this.selectedTerm){
                this.setModal(true);
                this.setModalTitle('Error');
                this.setModalMessage('Please set the Academic Year and Term to continue.');
                this.setModalIcon('error');
                return;
            }

            this.overlay = false;
            this.setOverlay(true);
            this.$emit('progress-type', 'linear');
            await this.clearFlags();
            this.postTermRegistration();               
            
        },

        async postTermRegistration () {
            
            this.register().then(response => {                
                let rows_processed = response.data.rows_processed;
                let rows_total = response.data.total_rows;               
                this.setTermRegistrationProgress((rows_processed / rows_total) * 100);
                
                if(response.data.rows_processed != response.data.total_rows)
                this.postTermRegistration();
                else{                    
                    this.setOverlay(false);
                    this.setModal(true);
                    this.setModalTitle('');
                    this.setModalMessage('Students Registered Successfully.');
                    this.setModalIcon('success');
                    this.setTermRegistrationProgress(0);
                }
            }).catch(error => {
                this.setOverlay(false);
                if(error.response) console.log(error.response);
                else console.log(error);
                this.setModal(true);
                this.setModalTitle('Error');
                this.setModalMessage('An Error has Occurred. Please contact Admin.');
                this.setModalIcon('error');
            })
        },

        save(date){
            this.$refs.menu.save(date)
        },

        async setTerm(){
            if(this.selectedYear && this.selectedTerm) {
                console.log('setting term');
                let academicyear = this.selectedYear.replace(/\s+/g, '');            
                let academicYearId = academicyear.replace('-', '');
                //let year = academicYearId.substring(0,4);
                
                let academicTerm = {
                    term: this.selectedTerm,
                    new_term_beginning: this.date,
                    possible_attendance: this. possibleAttendance,
                    academic_year_id: academicYearId,
                    start: '',
                    end: '',
                    is_current: 1
                }
                this.setAcademicTerm(academicTerm);
                try {
                    await this.setCurrentAcademicTerm(academicTerm);

                    await this.initialize();
                    
                    this.setModal(true);
                    this.setModalTitle('');
                    this.setModalMessage('Term Set Successfully.')
                    this.setModalIcon('success')
                    
                } catch (error) {
                    if(error.response) console.log(error.response)
                    else console.log(error);
                    this.setModal(true);
                    this.setModalTitle('Error');
                    this.setModalMessage(error);
                    this.setModalIcon('error');
                }
                
            }
            else{
                this.setModal(true);
                this.setModalTitle('Error');
                this.setModalMessage('Please set the Academic Year and Term to continue.');
                this.setModalIcon('error');
            }
        },

        registerStudentsMenu () {
            this.overlay = true;
        },

        getNewTermBeginning () {
            let academicyear = this.selectedYear.replace(/\s+/g, '');            
            let academicYearId = academicyear.replace('-', '');
            this.termArchives.forEach(record => {
                if(
                    record.academic_year_id == academicYearId &&
                    record.term == this.selectedTerm
                ){
                    this.date = record.new_term_beginning;
                }
            })
        }
    }
}
</script>