<template>
    <v-col class="pr-0 pb-0 mb-0">
        <v-row class="mr-0">
            <v-card
                flat
                :width="width"
                align="center"
                justify="center"
                color="grey lighten-5"
                dark
            >
                <span 
                    class="mr-3 text-subtitle-1 green--text"                                         
                > {{ studentName }}</span>                                 
                <span 
                    class="text-subtitle-1 green--text"
                >{{ classId }}</span>
            </v-card>
        </v-row>

        <v-row class="mr-0">
            <v-card                                    
                :width="width"
                flat
                color="grey lighten-5"                
                dark                                    
            >
                <v-row
                    class="px-3"
                >
                    <v-col
                        align="center"
                        justify="center"
                        v-for="item in academicPeriod"
                        :key="item.title"
                        class="pa-0"
                    >
                        <div 
                            class="text-caption blue--text"
                        >{{item.title}}</div>
                        <div 
                            class="text-caption green--text"
                        >{{item.value}}</div>
                    </v-col>
                </v-row>
            </v-card>                                 
        </v-row>

        <v-row class="mr-0">
            <v-card
                height="260"
                :width="width"
                flat
            >
                <div>
                    <v-list class="py-0" dense>
                        <v-list-item
                            v-for="item in recordSummary"
                            :key="item.title"
                            class="pr-0 mb-2"
                        >
                            <v-list-item-content class="py-0">
                                <v-list-item-title 
                                    class="text-caption"
                                >
                                    {{ item.title }}
                                </v-list-item-title>
                            </v-list-item-content>
                            <v-card                               
                                width="150"                                                                                                       
                                flat
                            >
                                <v-text-field
                                    v-if="!item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled" 
                                    @blur="updateRecord"
                                    @input="checkNumber(item.model)"
                                    dense
                                    outlined
                                    hide-details
                                    v-model="studentRecord[item.model]"
                                    height="30"
                                    class="text-caption pa-0"
                                    type="number"
                                ></v-text-field>

                                <v-select
                                    v-else-if="item.select"
                                    :disabled = "item.disabled"
                                    :filled = "item.disabled"
                                    :items="grades2"
                                    item-text="grade"
                                    item-value="grade"                                     
                                    v-model="studentRecord[item.model]"
                                    hide-details                                    
                                    outlined
                                    class="text-caption text-center"
                                    @change="updateRecord"
                                    chips
                                    deletable-chips                                    
                                >  
                                    <template v-slot:item="{ item }">
                                        {{ item.grade }} {{ item.description }}    
                                    </template>                                 
                                </v-select>
                            </v-card> 
                        </v-list-item>
                    </v-list>
                </div>                                     
            </v-card>
        </v-row>

        <v-row class="mr-0">
            <v-card
                flat                
                class="px-3" 
                width="100%"               
            >
                <v-row 
                    class=""
                >
                    <v-col>
                        <v-textarea                                       
                            label="Principal's/Dean's Comment"  
                            v-model="studentRecord.deans"                          
                            persistent-hint
                            dense
                            rows="3"
                            counter                                            
                            class="text-caption"
                            no-resize
                            maxlength="255"
                            :readonly="disabledDeanComment"
                            :filled="disabledDeanComment"
                            @blur="updateRecord"
                            outlined
                        >                        
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-card>
                   
            <v-container fluid class="pt-0">
            <v-row>
                <v-col>
                    <v-btn                        
                        outlined
                        color="primary"                        
                        @click="searchStudent"
                        small
                        
                        block
                    >
                        Search      
                        <v-icon
                            medium
                            class="ml-2"
                        >mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            </v-container>
            
        </v-row>

        <v-row
            class="mt-2 mr-0 px-4"
        >
            <v-btn                
                color="primary"
                block
                small
                @click="nextClass"
            >
                Next Class
            </v-btn>
        </v-row>

        <v-row
            class="mt-3 mb-2 mr-0"
        >
            <v-card
                flat
                :width="width"
                class="d-flex justify-space-between pl-3"
            >
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"                
                    color="primary"
                    @click="previousRecord"
                    :disabled="!pagination.prev_page"                
                >
                    <div class="d-flex flex-column"> 
                        <span>PREV</span>
                    </div>                                         
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2"
                    width="15"
                    height="30"
                    outlined
                    disabled                
                    text                
                >
                    <span class="blue--text">{{pagination.current_page}} of {{ pagination.last_page}}</span>                                                        
                </v-btn>
                
                <v-btn 
                    class="text-caption pa-2  white--text"
                    width="15"
                    height="30"
                    dark
                    color="primary"
                    @click="nextRecord"
                    :disabled="!pagination.next_page"
                >
                    <div class="d-flex flex-column">
                        <span>NEXT</span>
                    </div>                                         
                </v-btn>
            </v-card>    
        </v-row>

        <v-overlay
            absolute
            :value="overlay"
            color="secondary"
            opacity="0.6"
        >
            <v-expand-transition>
                <v-card
                    max-width="600"
                    class="mx-auto pa-6"
                    transition="scroll-y-transition"
                    v-show="expand"
                    light
                >
                    <v-card-title
                        class="mb-6"
                    >
                        <v-text-field
                            v-model="search"
                            label="Search for Student Record"
                            append-icon="mdi-magnify"
                            class="mx-4"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="table1Records"
                        :search="search"
                        fixed-header
                        height="40vh"
                        :options="options"
                        dense
                    >
                        <template
                            v-slot:[`item.actions`]="{ item }"
                        >
                            <v-btn
                                text
                                color="primary"
                                @click="viewRecord(item)"
                            >
                                View Record
                            </v-btn>
                        </template>
                    </v-data-table>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            outlined
                            @click="cancelSearch"
                        >
                            Cancel Search
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>
        </v-overlay>

    </v-col>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    created(){
        this.initialize();
    },
    computed: {
        ...mapGetters({
            studentRecord: 'termReports/getSelectedStudentRecord',            
            academicYear: 'termReports/getAcademicYear',
            currentTerm: 'termReports/getCurrentTerm',
            primaryColor: 'termReports/getPrimaryColor',
            colorDarken: 'termReports/getColorDarken',
            formTeacherClass: 'termReports/getFormTeacherClass',
            codedComments: 'termReports/getCodedComments',
            table1Records: 'termReports/getTable1Records',
            pagination: 'termReports/getPagination',
            formClasses: 'termReports/getFormClassesList',
            admin: 'auth/getAdmin',
            deanFormClasses: 'termReports/getDeanFormClasses',
        }),

        studentName () {
            return this.studentRecord.first_name + ' ' + this.studentRecord.last_name;
        },

        classId () {
            return this.studentRecord.class_id;
        },

        recordSummary () {
            return [
                {title: 'Student\'s ID', model: 'student_id', select: false, disabled: true, section: 1},
                {title: 'Possible Attendance', model: 'possible_attendance', select: false, disabled: true, section: 1},
                {title: 'Sessions Absent', model: 'times_absent', select: false, disabled: false, section: 1},
                {title: 'No. Times Late', model: 'times_late', select: false, disabled: false, section: 1},
                {title: 'Conduct', model: 'conduct', select: true, disabled: false, section: 1},
                {title: 'Deportment', model: 'deportment', select: true, disabled: false, section: 1},
            ]
        },

        termName () {
            let termName = '';
            if(this.currentTerm == 1) termName =  '1';
            if(this.currentTerm == 2) termName = '2';
            if(this.currentTerm == 3) termName = '3';
            return termName
        },

        extraCurricularRows () {
            return this.extraCurricular.filter(value => value.sectionRow);
        },       
        
        
    },
    watch: {
        studentRecord: {
            handler () {                
                this.setDisabledFields();
            }
        },

    },
    data: () => ({
        academicPeriod: [
            { title: 'Year', value: '' },
            { title: 'Term', value: '' },
        ],
        grades: [            
            {grade: 'A', scale: '80 - 100'},
            {grade: 'B', scale: '70 - 79'},
            {grade: 'C', scale: '60 - 69'},
            {grade: 'D', scale: '55 - 59'},
            {grade: 'E', scale: '50 - 54'},
            {grade: 'U', scale: '0 - 49'},
                     
        ],
        grades2: [
            { grade: 'A', description: 'Outstanding'},
            { grade: 'B', description: 'Very Good'},
            { grade: 'C', description: 'Good'},
            { grade: 'D', description: 'Fair'},
            { grade: 'E', description: 'Poor'},
        ],
        headers: [
            { text: 'Student ID', align: 'center', value: 'student_id', width: '75' },
            { text: 'First Name', align: 'left', value: 'first_name', width: '100' },
            { text: 'Last Name', align: 'left', value: 'last_name', width: '100' },
            { text: '', align: 'center', value: 'actions', sortable: false, width: '150' },
        ],
        options: {
            itemsPerPage: -1,
        },  
        codedComment: { id: null, comment: null },  
        disabled: false,
        expand: false,
        overlay: false,
        search: '',
        width: '330', 
        disabledDeanComment: false,
    }),
    methods: {
        ...mapActions({ 
            postTable1Record: 'termReports/postTable1Record',
            getTable2Records: 'termReports/getTable2Records', 
            setErrorResponse: 'termReports/setErrorResponse',            
        }),        
        ...mapMutations({         
            setSaveStatus: 'termReports/setEditViewTermReportsSaveStatus',
            setSaving: 'termReports/setEditViewTermReportsSaving',
            setSaveError: 'termReports/setEditViewTermReportsSaveError',
            setSaved: 'termReports/setEditViewTermReportsSaved',
            setOverlay: 'termReports/setEditViewTermReportsOverlay',
            setPagination: 'termReports/setPagination',           
            setLoading: 'termReports/setEditViewTermReportsLoading',
            setSelectedStudentRecord: 'termReports/setSelectedStudentRecord',
            setTable2Records: 'termReports/setEditViewTermReportsTable2Records',
            setStudentSubjects: 'termReports/setEditViewTermReportsStudentSubjects', 
            setExpand: 'termReports/setEditViewTermReportsExpand',
        }),

        initialize(){            
            this.academicPeriod[0].value = this.academicYear;
            this.academicPeriod[1].value = this.termName;            
            //console.log(this.codedComments);   
        },

        setRecordSummary(){
            //
        },

        async updateRecord(){
            console.log('updating...');
            //console.log(this.studentRecord);
            this.setSaveStatus('Saving...');
            this.setSaving(true);
            this.setSaved(false);
            this.setSaveError(false);
            //this.studentRecord.rel_comment = this.codedComment.id;            
            //console.log(this.studentRecord);
            try{
                await this.postTable1Record();
                this.setSaveStatus('Record Saved');
                this.setSaving(false);
                this.setSaved(true);
                this.setSaveError(false);
            } catch (error) {
                console.log(error);
                this.setSaveStatus('Error Occured');
                this.setSaving(false);
                this.setSaved(false);
                this.setSaveError(true);
                this.setErrorResponse(error);
            }
            
        },

        searchStudent(){
            this.overlay = true;
            setTimeout(() => {
                this.expand = true;
            })
        },
        viewRecord(item){
            //console.log(item);
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
            let recordIndex = this.table1Records.indexOf(item);
            this.recordNavigation(++recordIndex);
        },
        cancelSearch(){
            this.expand = false;
            setTimeout(() => {
                this.overlay = false;
            }, 600);
        },

        async recordNavigation(pagination){
            let response = null;
            this.setOverlay(true);
            this.setLoading(true);
            
            this.makePagination(pagination);
            this.setSelectedStudentRecord(this.table1Records[--pagination]);
            try {
                response = await this.getTable2Records();
                this.setTable2Records(response.data.table2_records);
                this.setStudentSubjects(response.data.student_subjects);
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
            }            
            this.setOverlay(false);
            this.setLoading(false);           
        },

        makePagination(data){
            let prev_page = data;
            let current_page = data;
            let next_page = (current_page === this.pagination.last_page) ? null : ++data;
            prev_page = (current_page === 1) ? null : --prev_page;
            
            let pagination = {
                current_page: current_page,
                last_page: this.pagination.last_page,
                next_page: next_page,
                prev_page: prev_page,
            };
            this.setPagination(pagination);
        },

        async previousRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.prev_page);
            // console.log('previous');
        },

        nextRecord(){
            this.setSaveStatus('');
            this.setSaving(false);
            this.setSaved(false);
            this.setSaveError(false);
            this.recordNavigation(this.pagination.next_page);
            // console.log('next');
        },

        cardWidth (item, index ){
            if(index == 1) return '100px';
            if(item.type == 'select') return '100px'
            return '65px'
        },

        setDisabledFields () {
            this.disabled = false;
            this.recordSummary.forEach(element => {
                element.disabled = false;
            })
            if(this.formTeacherClass.indexOf(this.studentRecord.class_id) === -1 && !this.admin){
                //console.log('Not the form Teacher');
                this.disabled = true;
                this.recordSummary.forEach(element => {
                    element.disabled = true;
                })                    
            }
            // console.log(`Disabled: ${this.disabled}`);
            this.disabledDeanComment = false;
            if(this.deanFormClasses.indexOf(this.studentRecord.class_id) === -1 && !this.admin){
                this.disabledDeanComment = true;
            }
            
        },

        nextClass () {
            this.setOverlay(true);
            this.setExpand(true);
        },

        checkNumber (model) {
            const validNumber = /^[0-9]+$/.test(this.studentRecord[model]);
            console.log(validNumber)
            if(!validNumber){
                this.studentRecord[model] = null;
            }
        }
        
    },

   
}
</script>

<style scoped>
    ::v-deep .v-input__slot{
        min-height: 0px !important;
        display: flex !important;
        align-items: center !important;
    }

    ::v-deep .v-list--dense .v-list-item{
        min-height: 32px;
    }

    .v-btn__content{
        font-size: 0.9em;
    }

    ::v-deep .v-input__append-inner{
        margin-top: 0;
        padding-left: 0 !important;
    }

    ::v-deep .v-select__selections{
        height: 30px;
        /*padding: 0 !important;*/
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    ::v-deep .v-select__selection--comma{
        margin: 0 4px 4px 0;
    }

    ::v-deep .v-text-field__slot input{
        text-align: center;
    }

    ::v-deep .v-chip.v-size--default{
        height: 20px;
        font-size: 11px;
    }

    /* ::v-deep .times_late .v-chip.v-size--default{
        margin-top: 0;
    } */

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
            min-height: 8px;
        }

    ::v-deep .v-select.v-select--chips .v-select__selections{
        min-height: 8px;
    }

    ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }

    /* ::v-deep .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
        padding: 0 2px;
    } */

    /* ::v-deep .v-application--is-ltr .v-text-field .v-input__append-inner{
        padding-left: 0;
    }     */
</style>