<template>
    <v-container class="pb-0 d-flex justify-center">
        <v-card
            flat
            width="900"
            class="px-6"
        >
            <v-data-table
                :items="table2Records"
                :loading="loading"
                :headers="headers"
                :height="height"            
                fixed-header           
                class="enter-marks-table"
                disable-pagination
                hide-default-footer                                       
            >
                <template v-slot:item="props">
                    <Record
                        v-bind:studentRecord="props.item"
                        v-bind:formLevel="lessonSelected.formLevel"
                    />
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>

<script>
import Record from './EnterMarksSheetRecord';
import { mapGetters } from 'vuex';
export default {
    components: {
        Record,
    },
    
    data: () =>({
        loading: false,
        height: "55vh",
        pagination: false,
        footer: false,                
        headers: [],
        p1Weight: null,
        p2Weight: null,
        p3Weight: null,       
        iaWeight: null,        
    }),    
    computed:{
        ...mapGetters({            
            table2Records: 'termReports/getTable2Records',
            currentTerm: 'termReports/getCurrentTerm',
            lessonSelected: 'termReports/getLessonSelected',
        }),

    },
    watch: {
        // lessonSelected: {
        //     handler(){
        //         this.initialize();
        //     }
        // },
        table2Records: {
            handler () {
                this.initialize();
            }
        }
       
    },
    methods: { 
       initialize(){
           this.headers = [
            {text: '', value: 'count', align: 'left', width: '45', sortable: false},
            {text: 'Name', value: 'name', width: '265', sortable: false},
            {text: 'Course', value: 'course', align: 'center', width: '65', sortable: false,},            
            {text: 'Exam', value: 'exam', align: 'center', width: '65', sortable: false,},                    
            {text: 'Coded Comment 1', value: 'codedComment1', align: 'center', width: '100', sortable: false,},            
            {text: 'Coded Comment 2',  value: 'codedComment2', align: 'center', width: '100', sortable: false,},
            {text: ' ', value: 'actions', align: 'center', sortable: false, width: '40'},                        
        ]    
       }
    }
}
</script>

<style scoped>
    .v-text-field__slot textarea{
        font-size: 13px;
    }
    .v-data-table > .v-data-table__wrapper > table{
        table-layout: fixed;
    }
    th span{
        display: flex;
    }
    ::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
        padding: 0 6px;
    }
</style>